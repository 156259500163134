import React from "react";

export const ReturnPolicy = () => {
    return (
        <>
            <div style={{ paddingLeft: '5%', paddingRight: '5%', paddingTop: '5%' }}>
                <center>
                    <h1 style={{color: '#000000', fontSize: '16pt', fontFamily: "Times New Roman"}}>RETURN POLICY</h1>
                    <hr style={{ border: '1px solid #000', width: '100%', margin: 'auto' }}></hr>
                </center>
                <div>
                    <p className="paratext">Last updated date: Feb 20, 2024</p>
                </div>
                <div>
                    <p className="paratext">
                        This Return Policy ("Policy") outlines the terms and conditions governing the cancellation, return, exchange, and refund of products purchased on Ro-one Information Technology & Digital Solutions Private Limited  App/Website (referred to as "the Platform" hereafter).
                    </p>
                    <p className="headtext"><b>1. ELIGIBILITY FOR RETURN OR EXCHANGE</b></p>
                    <p className="subparatext">1.1 The Products sold on the Platform are eligible for return or exchange only under the following circumstances</p>
                    <p className="parasubtext">1.1.1 The item received by the customer is damaged, expired, or the wrong product is sent.</p>
                    <p className="subparatext">1.2 Customers must notify Ro-one Information Technology & Digital Solutions Private Limited  ("the Company") for a replacement within 3 days of the product's delivery.</p>
                    <p className="headtext"><b>2. INELIGIBILITY FOR RETURN OR EXCHANGE</b></p>
                    <p className="subparatext">2.1 Returns or exchanges will not be accepted under the following circumstances</p>
                    <p className="parasubtext">2.1.1 Accessories such as food, treats, etc., are non-returnable and non-refundable.</p>
                    <p className="parasubtext">2.1.2 Any return request submitted beyond 7 days of the order being delivered.</p>
                    <p className="parasubtext">2.1.3 The product has been used or altered in any way.</p>
                    <p className="parasubtext">2.1.4 The product has been damaged through abnormal use, i.e., incorrect use by the customer.</p>
                    <p className="parasubtext">2.1.5 Unopened units in combo products. For example, if a product comprises 12 packets in a combo, and one packet is used, return or exchange requests for the remaining packets will not be accepted.</p>
                    <p className="headtext"><b>3. ORDER CANCELLATION</b></p>
                    <p className="subparatext">
                        3.1 Customers may cancel any order before the dispatch of the item by contacting the Company at the provided customer service channels.</p>
                    <p className="subparatext">3.2 To initiate a return request, customers can contact the Company at onelovepc2021@gmail.com</p>
                    <p className="subparatext">3.3 The Platform retains the right to cancel any order at its sole discretion and will notify the customer through email/SMS. The Platform may cancel an order if quantities exceed standard consumption or if there is a reason to believe the order is not for personal use.
                    </p>
                    <p className="headtext"><b>4. CUSTOMER SUPPORT</b></p>
                    <p className="subparatext">4.1 For any questions regarding returns, cancellations, or other product-related concerns, customers can contact the Company at onelovepc2021@gmail.com.</p>
                    <p className="headtext"><b>5. DAMAGES AND ISSUES</b></p>
                    <p className="subparatext">5.1 Customers are requested to inspect their orders upon receipt. Any defects, damages, wrong items, or items lost in transit must be reported to the Company within 7 days of delivery.</p>
                    <p className="headtext"><b>6. REFUNDS</b></p>
                    <p className="subparatext">6.1 Upon receipt and inspection of the returned product, the Company will notify the customer of the refund status.</p>
                    <p className="subparatext">6.2 Approved refunds will be automatically processed to the original payment method.</p>
                    <p className="subparatext">6.3 In case of order cancellation, any amount paid by the customer will be refunded to the original payment method.</p>
                    <p className="subparatext">6.4 Please note that the processing and posting of refunds by banks or credit card companies may take some time.</p>
                    <p className="paratext">
                        This Return Policy is effective immediately and is subject to change at the discretion of Ro-one Information Technology & Digital Solutions Private Limited  without prior notice. Customers are advised to review the Policy periodically for updates.
                    </p>
                </div>
            </div>
        </>
    )
}