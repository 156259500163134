import React, { useState } from "react";
import "../Pages/Landingpage.css";
import { FaUserCircle } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { clearToken, clearUserData } from "../Redux/Actions";
import { useNavigate } from "react-router-dom";

function TopNavBar() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };


  const userData = localStorage.getItem("adminData");
  const parsedata = JSON.parse(userData);
  console.log("parsedata", parsedata);
  const userAdminData = useSelector((state) => state.user.userData);
  console.log("userAdminData", userAdminData);

  const handleLogout = () => {
    dispatch(clearUserData());
    dispatch(clearToken());
    localStorage.clear();
    handleClose();
    navigate('/');
  };

  return (
    <>
      <nav className="navbar header navbar-expand-lg fixed-top bg-body-tertiary">
        <div className="container-fluid">
          <button className="logo-button" onClick={handleLogoClick}>
            <img src="/image.png" alt="logo" className="onelovelogo" />
            <p>PETHOOD</p>
          </button>
          <button
            className="navbar-toggler nav-iconn"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-1 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/Aboutus">
                  About us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/contact">
                  Contact us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/PrivacyPolicy">
                  Privacy policy
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/terms-conditions">
                  Terms & conditions
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/RefundPolicy">
                  Refund policy
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/ReturnPolicy">
                  Return policy
                </a>
              </li>
              {parsedata && (
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" href="/AllUsers">
                    All Users
                  </a>
                </li>
              )}
              {parsedata && (
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" href="/Subscriptions">
                    Subscriptions
                  </a>
                </li>
              )}
              {parsedata && (
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" href="/AllFeedBacks">
                    FeedBacks
                  </a>
                </li>
              )}
              {parsedata ? (
                <li className="nav-item">
                  <button className="nav-link btn btn-link" onClick={handleShow}>
                    <FaUserCircle size={30} />
                  </button>
                </li>
              ) : (
                <li className="nav-item">
                  <a className="nav-link" href="/LoginForm">Login</a>
                </li>
              )}
            </ul>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>User Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {parsedata && (
              <div>
                <p><strong>User Mail:</strong> {parsedata.mail}</p>
                <p><strong>User Name:</strong> {parsedata.admin_name}</p>
                <p><strong>Mobile Number:</strong> {parsedata.mobile}</p>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleLogout}>
              Logout
            </Button>
          </Modal.Footer>
        </Modal>
      </nav>
    </>
  );
}

export default TopNavBar;
