import React from "react";

export const PrivacyPolicy = () => {
    return (
        <>
            <div style={{ paddingLeft: '5%', paddingRight: '5%', paddingTop: '5%' }}>
                <center>
                    <p><b>PRIVACY POLICY</b></p>
                    <hr style={{ border: '1px solid #000', width: '100%', margin: 'auto' }}></hr>
                </center>
                <div style={{ textAlign: 'right' }}>
                    <p>Posted as of 01 February, 2022</p>
                    <p>Last updated as of 29 June, 2022</p>
                </div>
                <div>
                    <p className="paratext">
                        This legal agreement is an electronic record in terms of the <b>Indian Information Technology Act, 2000</b> and rules there under as applicable and the amended provisions about electronic records in various statutes as amended by the <b>Indian Information Technology Act, 2000.</b> This electronic record is generated by a computer system and does not require any physical or digital signatures.
                    </p>
                    <p className="paratext">
                        This legal document is published in accordance with the provisions of <b>Rule 3 (1)</b> of the <b>Indian Information Technology (Intermediaries guidelines) Rules, 2011</b> and <b>Rule 4 </b>of the <b>Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 </b>of <b>Information Technology Act, 2000 </b>amended through <b>Information Technology Amendment Act, 2008</b> that require publishing the Terms of Use and practices for access and usage of any functional Application.
                    </p>
                    <p className="paratext">
                        This Application is created and operated by M/s Ro-one Information Technology & Digital Solutions Private Limited , (hereinafter referred to as “We”, “Our”, and “Us”) and operating under the brand name “Pethood” (“Brand name”). The creator of this Privacy Policy ensures a steady commitment to Your privacy with regard to the protection of your invaluable information that you may share across on this Application. This privacy policy contains information about the Application “Pethood” (“Application”).
                    </p>
                    <p className="paratext">
                        For the purpose of these Privacy Policy (“Privacy Policy”), wherever the context so requires, “We”, “Our”, and “Us” shall mean and refer to the Application. “You”, “Your”, “Yourself”, “User” shall mean and refer to natural and legal individuals who shall be users of this Application provided by us and who is competent to enter into binding contracts, as per Indian laws. “Third Parties” refer to any Application or individual apart from the Users and the creator of this Application
                    </p>
                    <p className="paratext">
                        To provide You with Our uninterrupted use of the Application, We may collect and, in some circumstances, disclose information about you with your permission. To ensure better protection of Your privacy, We provide this notice explaining Our information collection and disclosure policies, and the choices You make about the way Your information is collected and used.
                    </p>
                    <hr style={{ border: '1px solid #000', width: '100%', margin: 'auto' }}></hr>
                    <p className="paratext">
                        <b>
                            This Privacy Policy shall be in compliance with the General Data Protection Regulation (GDPR) in effect from May 25, 2018, and any and all provisions that may read to the contrary shall be deemed to be void and unenforceable as of that date. If you do not agree with the terms and conditions of our Privacy Policy, including in relation to the manner of collection or use of your information, please do not use or access the Site. If you have any questions or concerns regarding this Privacy Policy, you should contact our Customer Support Desk at “onelovepc2021@gmail.com”.
                        </b>
                    </p>
                    <hr style={{ border: '1px solid #000', width: '100%', margin: 'auto' }}></hr>
                    <p className="paratext">
                        ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE MEANING ACCORDED TO THEM UNDER THIS AGREEMENT. FURTHER, ALL HEADING USED

                        HEREIN ARE ONLY FOR THE PURPOSE OF ARRANGING THE VARIOUS PROVISIONS OF THE AGREEMENT IN ANY MANNER. NEITHER THE USER NOR THE CREATORS OF THIS PRIVACY POLICY MAY USE THE HEADING TO INTERPRET THE PROVISIONS CONTAINED WITHIN IT IN ANY MANNER.
                    </p>
                    <center style={{ fontWeight: 'bold'}}>INFORMATION WE COLLECT</center>
                    <p className="paratext">
                        We are committed to respecting Your online privacy. We further recognize Your need for appropriate protection and management of any Personal Information You share with us. We may collect the following information:
                    </p>
                    <p className="paratext">
                        a. Personal data including but not limited to Name, Phone Number, Email ID, Address, City.
                    </p>
                    <p className="paratext">
                        b. Tracking Information such as, but not limited to the IP address of your device and Device ID when connected to the Internet. This information may include the URL that you just came from (whether this URL is on the Application or not), which URL you next go to (whether this URL is on the Application or not), your computer or device browser information, and other information associated with your interaction with the Application;
                    </p>
                    <p className="paratext">
                        This privacy policy also applies to data we collect from users who are not registered as members of this Application, including, but not limited to, browsing behaviour, pages viewed etc. We also collect and store personal information provided by You from time to time on the Application. We only collect and use such information from you that we consider necessary for achieving a seamless, efficient and safe experience, customized to your needs including:
                    </p>
                    <p className="paratext">a. To enable the provision of services opted for by you;</p>
                    <p className="paratext">b. To enable the viewing of content in your interest;</p>
                    <p className="paratext">c. To communicate the necessary account and service-related information from time to time;</p>
                    <p className="paratext">d. To allow you to receive quality customer care services and data Collection;</p>
                    <p className="paratext">e. To comply with applicable laws, rules and regulations;</p>
                    <p className="paratext">
                        Where any service requested by You involves a third party, such information as is reasonably necessary by the Firm to carry out Your service request may be shared with such third party. We also do use your contact information to send you offers based on your interests and prior activity and also to view the content preferred by you. The Firm may also use contact information internally to direct its efforts for service improvement but shall immediately delete all such information upon withdrawal of your consent for the same through the ‘unsubscribe’ button or through an email to be sent to “onelovepc2021@gmail.com”.
                    </p>
                    <p className="paratext">
                        To the extent possible, we provide You with the option of not divulging any specific information that you wish for us not to collect, store or use. You may also choose not to use a particular service or feature on the Application and opt-out of any non-essential communications from the Application.
                    </p>
                    <p className="paratext">
                        Further, transacting over the internet has inherent risks which can only be avoided by you following security practices yourself, such as not revealing account/login related information to any other person and informing our customer care team about any suspicious activity or where your account has/may have been compromised.
                    </p>
                    <center style={{ fontWeight: 'bold'}}>OUR USE OF YOUR INFORMATION</center>
                    <p className="paratext">
                        The information provided by you shall be used to provide and improve the service for you and all users.
                    </p>
                    <p className="paratext">a. To provide you with services on your request.</p>
                    <p className="paratext">b. For maintaining an internal record.</p>
                    <p className="paratext">c. For enhancing the Services provided.</p>
                    <p className="paratext">d. For maintaining record under the legal and statutory provisions.</p>
                    <p className="paratext">
                        For more details about the nature of such communications, please refer to our Terms of Service. Further, your data and Sensitive Personal data may be collected and stored by Us for internal record.
                        We use Your tracking information such as IP addresses, and or Device ID to help identify you and to gather broad demographic information and make further services available to you.<br></br>
                        We will not sell, license or trade Your personal information. We will not share your personal information with others unless they are acting under our instructions or we are required to do so by law.
                        Information collected via Our server logs includes users' IP addresses and the pages visited; this will be used to manage the web system and troubleshoot problems. We also use third-party analytics, tracking, optimization and targeting tools to understand how users engage with our Application so that we can improve it and cater personalized content/ads according to their preferences.
                    </p>
                    <center style={{ fontWeight: 'bold'}}>HOW INFORMATION IS COLLECTED</center>
                    <p className="paratext">
                        Before or at the time of collecting personal information, we will identify the purposes for which information is being collected. If the same is not identified to you, you have the right to request the Firm to elucidate the purpose of collection of said personal information, pending the fulfilment of which you shall not be mandated to disclose any information whatsoever.
                    </p>
                    <p className="paratext">
                        We will collect and use your personal information solely to fulfil those purposes specified by us, within the scope of the consent of the individual concerned or as required by law. We will only retain personal information as long as necessary for the fulfilment of those purposes. We will collect personal information by lawful and fair means and with the knowledge and consent of the individual concerned.
                    </p>
                    <p className="paratext">
                        Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.
                    </p>
                    <center style={{ fontWeight: 'bold'}}>COOKIES</center>
                    <p className="paratext">
                        A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. By continuing to browse the site, you are agreeing to our use of cookies. Cookies contain information that is transferred to your computer’s hard drive. You can set your browser to refuse all or some browser cookies, or to alert you when Applications set or access cookies. If you disable or refuse cookies, please note that some parts of this Application may become inaccessible or not function properly. A list of the type of cookies we use is as follows;
                    </p>
                    <p className="paratext">1.  Strictly necessary cookies. These are cookies that are required for the operation of our Application. They include, for example, cookies that enable you to log into secure areas of our Application, use a shopping cart or make use of e-billing services.</p>
                    <p className="paratext">2.  Analytical/performance cookies. They allow us to recognize and count the number of visitors and to see how visitors move around our Application when they are using it. This helps us to improve the way our Application works, for example, by ensuring that users are finding what they are looking for easily.</p>
                    <p className="paratext">3.  Functionality cookies. These are used to recognize you when you return to our Application. This enables us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</p>
                    <p className="paratext">4.  Targeting cookies. These cookies record your visit to our Application, the pages you have visited and the links you have followed. We will use this information to make our Application and the advertising displayed on it more relevant to your interests. We may also share this information with third-parties for this purpose.</p>
                    <p className="paratext">
                        Please note that third-parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies. You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our Application.
                    </p>
                    <center style={{ fontWeight: 'bold'}}>EXTERNAL LINKS ON THE APPLICATION</center>
                    <p className="paratext">
                        The Application may include advertisements, hyperlinks to other Applications or resources. We have no control over any other Application or resources or contents available on these other Applications, which are provided by companies or persons other than Us. You acknowledge and agree that we are not responsible for the availability of any such external sites or resources, and do not endorse any advertising, services or other materials on or available from such Application or resources. You acknowledge and agree that We are not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any advertising, services or other materials on, or available from, such Applications. These external third-party Applications and resource providers may have their privacy policies governing the collection, storage, retention and disclosure of Your Personal Information that You may be subject to. We recommend that you enter the Application and review their privacy policy.
                    </p>
                    <center style={{ fontWeight: 'bold'}}>PERMISSION AND ACCESS FROM THE DEVICE</center>
                    <p className="paratext">The Application requires permission to access the following features:</p>
                    <p className="paratext">a. Location</p>
                    <p className="paratext">b. Camera</p>
                    <center style={{ fontWeight: 'bold'}}>YOUR RIGHTS</center>
                    <p className="paratext">Unless subject to an exemption, you have the following rights concerning your data:</p>
                    <p className="paratext">a.  The right to request a copy of your data which we hold about you;</p>
                    <p className="paratext">b.  The right to request for any correction to any personal data if it is found to be inaccurate or out of date;</p>
                    <p className="paratext">c.  The right to withdraw Your consent to the processing at any time;</p>
                    <p className="paratext">d.  The right to object to the processing of personal data;</p>
                    <p className="paratext">e.  The right to complain about a supervisory authority.</p>
                    <p className="paratext">f.  The right to obtain information as to whether personal data are transferred to a third country or an international organization.</p>
                    <p className="paratext">Where you hold an account with any of our services, you are entitled to a copy of all personal data which we hold concerning you. You are also entitled to request that we restrict how we use your data in your account when you log in.</p>
                    <center style={{ fontWeight: 'bold'}}>CONFIDENTIALITY</center>
                    <p className="paratext">
                        You further acknowledge that the Application may contain information that is designated confidential by us and that you shall not disclose such information without our prior written consent. Your information is regarded as confidential and therefore will not be divulged to any third party, unless if legally required to do so to the appropriate authorities. We will not sell, share, or rent your personal information to any third party or use your e-mail address for unsolicited mail. Any emails sent by us will only be in connection with the provision of agreed services, and you retain sole discretion to seek for discontinuation of such communications at any point in time.
                    </p>
                    <center style={{ fontWeight: 'bold'}}>OTHER INFORMATION COLLECTORS</center>
                    <p className="paratext">
                        Except as otherwise expressly included in this Privacy Policy, this document only addresses the use and disclosure of information we collect from you. To the extent that you disclose your information to other parties, whether they are on our Application or other sites throughout the Internet, different rules may apply to their use or disclosure of the information you disclose to them. To the extent that we use third party advertisers, they adhere to their privacy policies. Since we do not control the privacy policies of the third parties, you are subject to ask questions before you disclose your personal information to others.
                    </p>
                    <center style={{ fontWeight: 'bold'}}>OUR DISCLOSURE OF YOUR INFORMATION</center>
                    <p className="paratext">
                        We may host surveys for survey creators for our Application who are the owners and users of your survey responses. We do not own or sell your responses. Anything you expressly disclose in your responses will be disclosed to survey creators. Please contact the survey creator directly to better understand how they might share your survey responses.
                    </p>
                    <p className="paratext">
                        Information collected will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005, any rules made thereunder or any other law for the time being in force.
                    </p>
                    <p className="paratext">
                        Due to the existing regulatory environment, we cannot ensure that all of your private communications and other personally identifiable information will never be disclosed in ways not otherwise described in this Privacy Policy. By way of example (without limiting and foregoing), we may be forced to disclose information to the government, law enforcement agencies or third parties. Therefore, although we use industry-standard practices to protect your privacy, we do not promise, and you should not expect, that your personally identifiable information or private communications would always remain private. We do however assure you that any disclosure of your personally identifiable information shall be personally intimated to you through an email sent to your provided email address.
                    </p>
                    <p className="paratext">
                        As a matter of policy, we do not sell or rent any personally identifiable information about you to any third party. However, the following describes some of the ways that your personally identifiable information may be disclosed:
                    </p>
                    <p className="paratext">a. External Service Providers: There may be several services offered by external service providers that help you use our Application. If you choose to use these optional services, and in the course of doing so, disclose information to the external service providers, and/or permit them to collect information about you, then their use of your information is governed by their privacy policy.</p>
                    <p className="paratext">b.  Law and Order: We cooperate with law enforcement inquiries, as well as other third parties to enforce laws, such as intellectual property rights, fraud and other rights. We can (and you authorize us to) disclose any information about you to law enforcement and other government officials as we, in our sole discretion, believe necessary or appropriate, in connection with an investigation of fraud, intellectual property infringements, or other activity that is illegal or may expose us or you to legal liability.</p>
                    <center style={{ fontWeight: 'bold'}}>ACCESSING, REVIEWING AND CHANGING YOUR PROFILE</center>
                    <p className="paratext">
                        Following registration, you can review and change the information you submitted at the stage of registration, except Email ID and mobile number. An option for facilitating such change shall be present on the Application and such change shall be facilitated by the User. If you change any information, we may or may not keep track of your old information. We will not retain in our files information you have requested to remove for certain circumstances, such as to resolve disputes, troubleshoot problems and enforce our terms and conditions. Such prior information shall be completely removed from our databases, including stored ‘back up’ systems. If you believe that any information, we are holding on to you is incorrect or incomplete, or to remove your profile so that others cannot view it, the User needs to remediate, and promptly correct any such incorrect information.
                    </p>
                    <center style={{ fontWeight: 'bold'}}>SECURITY</center>
                    <p className="paratext">We treat data as an asset that must be protected against loss and unauthorized access. We employ many different security techniques to protect such data from unauthorized access by members inside and outside the Firm. We follow generally accepted industry standards to protect the Personal Information submitted to us and information that we have accessed.</p>
                    <p className="paratext">However, as effective as encryption technology is, no security system is impenetrable. Our Firm cannot guarantee the security of our database, nor can we guarantee that information you provide won’t be intercepted while being transmitted to the Firm over the Internet.</p>
                    <center style={{ fontWeight: 'bold'}}>SEVERABILITY</center>
                    <p className="paratext">Each paragraph of this Privacy Policy shall be and remain separate from and independent of and severable from all and any other paragraphs herein except where otherwise expressly indicated or indicated by the context of the agreement. The decision or declaration that one or more of the paragraphs are null and void shall not affect the remaining paragraphs of this privacy policy.</p>
                    <center style={{ fontWeight: 'bold'}}>AMENDMENT</center>
                    <p className="paratext">
                        Our Privacy Policy may change from time to time. The most current version of the policy will govern our use of your information and will always be on the Application. Any amendments to this Policy shall be deemed as accepted by the User on their continued use of the Application.
                    </p>
                    <center style={{ fontWeight: 'bold'}}>CONSENT WITHDRAWAL, DATA DOWNLOAD & DATA REMOVAL REQUESTS</center>
                    <p className="paratext">
                        To withdraw your consent, or to request the download or delete your data with us for any or all our services at any time, please email to “onelovepc2021@gmail.com”.
                    </p>
                    <center style={{ fontWeight: 'bold'}}>CONTACT US</center>
                    <p className="paratext">If you have any questions or concerns regarding this privacy policy, you should contact us by sending an e-mail to “onelovepc2021@gmail.com”.</p>
                </div>
            </div>
        </>
    )
}