import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import apiconfig from '../Config/Config';

const convertToIST = (utcDateStr) => {
    const date = new Date(utcDateStr);
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
        timeZone: 'Asia/Kolkata',
    };
    return date.toLocaleDateString('en-IN', options);
};

const Subscriptions = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const avdurl1 = apiconfig.develpoment.apibaseUrl;

    useEffect(() => {
        fetchSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchSubscriptions = async () => {
        try {
            const response = await axios.get(avdurl1 + 'admin/subscriptions');
            setSubscriptions(response.data.subscriptions);
        } catch (err) {
            setError('Failed to fetch subscriptions');
        } finally {
            setLoading(false);
        }
    };

    const handleDetailsClick = (subscription) => {
        setSelectedUser(subscription);
        setIsDialogOpen(true);
    };

    const handleRefundClick = async (id, userId) => {
        try {
            const response = await axios.post(
                avdurl1 + 'subscription/confirmcancellation', 
                { 
                    subscription_id: id, 
                    user_id: userId 
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );
            alert(`Refund processed for subscription ID: ${id}. Refund amount: ${response.data.refund_amount}`);
            // Optionally, refresh the subscriptions list
            fetchSubscriptions(); // Make sure this function is defined in your component
        } catch (error) {
            console.error('Error processing refund:', error);
            alert('Error processing refund');
        }
    };
    

    const columns = [
        { field: 'id', headerName: 'Subscription ID', width: 200 },
        { field: 'user_name', headerName: 'User Name', width: 180 },
        { field: 'plan_id', headerName: 'Plan ID', width: 150 },
        { field: 'status', headerName: 'Status', width: 100 },
        { field: 'amount', headerName: 'Amount', width: 100 },
        { field: 'start_date', headerName: 'Start Date', width: 180 },
        { field: 'end_date', headerName: 'End Date', width: 180 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 250,
            renderCell: (params) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    <Button variant="contained" color="primary" onClick={() => handleDetailsClick(params.row)}>
                        Details
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleRefundClick(params.row.id, params.row.user_id)}
                        disabled={params.row.status !== 'cancellationpending'}
                    >
                        {params.row.status === 'cancelled' ? 'Refunded' : 'Refund'}
                    </Button>
                </div>
            ),
        },
    ];

    if (loading) {
        return <p>Loading subscriptions...</p>;
    }

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    return (
        <div className="users-container">
            <center><h1>All Subscriptions</h1></center>
            <DataGrid
                rows={subscriptions}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[5, 10, 20]}
                disableSelectionOnClick
            />

            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogTitle>User Details</DialogTitle>
                <DialogContent>
                    {selectedUser && (
                        <>
                            <Typography><strong>Subscription ID:</strong> {selectedUser.id}</Typography>
                            <Typography><strong>User ID:</strong> {selectedUser.user_id}</Typography>
                            <Typography><strong>User Name:</strong> {selectedUser.user_name}</Typography>
                            <Typography><strong>Email:</strong> {selectedUser.user_email}</Typography>
                            <Typography><strong>Plan ID:</strong> {selectedUser.plan_id}</Typography>
                            <Typography><strong>Status:</strong> {selectedUser.status}</Typography>
                            <Typography><strong>Amount:</strong> {selectedUser.amount !== null ? selectedUser.amount : 'N/A'}</Typography>
                            <Typography>
                                <strong>Start Date:</strong> {selectedUser.start_date ? convertToIST(selectedUser.start_date) : 'N/A'}
                            </Typography>
                            <Typography>
                                <strong>End Date:</strong> {selectedUser.end_date ? convertToIST(selectedUser.end_date) : 'N/A'}
                            </Typography>
                            <Typography><strong>Created At:</strong> {new Date(selectedUser.created_at).toLocaleString()}</Typography>
                            <Typography><strong>Updated At:</strong> {new Date(selectedUser.updated_at).toLocaleString()}</Typography>
                            <Typography><strong>Clinic ID:</strong> {selectedUser.clinic_id || 'N/A'}</Typography>
                            <Typography><strong>Image ID:</strong> {selectedUser.image_id || 'N/A'}</Typography>
                            <Typography><strong>Is Active:</strong> {selectedUser.is_active ? 'Yes' : 'No'}</Typography>
                            <Typography><strong>Payment Done:</strong> {selectedUser.payment_done ? 'Yes' : 'No'}</Typography>
                            <Typography><strong>Services Left:</strong> {selectedUser.services_left !== null ? selectedUser.services_left : 'N/A'}</Typography>
                            <Typography><strong>Valid Document:</strong> {selectedUser.valid_doc}</Typography>
                            <Typography><strong>User Type:</strong> {selectedUser.user_type}</Typography>
                            <Typography><strong>Refund Amount:</strong> {selectedUser.refund_amount || 'N/A'}</Typography>
                            <Typography><strong>Refund Status:</strong> {selectedUser.refund_status}</Typography>
                            <Typography><strong>Refund ID:</strong> {selectedUser.refund_id}</Typography>
                            <Typography><strong>Refund Date:</strong> {selectedUser.refund_date ? convertToIST(selectedUser.refund_date) : 'N/A'}</Typography>
                            <Typography><strong>Refund Created At:</strong> {selectedUser.refund_createdat ? convertToIST(selectedUser.refund_createdat) : 'N/A'}</Typography>
                            <Typography><strong>Refund Updated At:</strong> {selectedUser.refund_updatedat ? convertToIST(selectedUser.refund_updatedat) : 'N/A'}</Typography>
                            <Typography><strong>Plan Use Days:</strong> {selectedUser.planusedays || 'N/A'}</Typography>
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Subscriptions;
